import React from 'react'
import PropTypes from 'prop-types'
import { H2 } from './Headings'
import { Carousel } from './Carousel'
import formatScopeOfWork from '../utils/format-scope-of-work'
import unesc from '../utils/unescape'

const InProgressMeta = props => {
  return (
    <div className="md-flex flex-wrap md-mxn1 h5 md-h6 line-height-3">
      {props.meta.map((item, index) => {
        if (!item.content) {
          return null
        }

        return (
          <div
            className="col-12 md-col-6 md-px1"
            key={`ProjectMeta_${item.name}_${index}`}>
            <strong className="uppercase">{item.name}</strong>
            <p className="mt0">{item.content}</p>
          </div>
        )
      })}
    </div>
  )
}

const InProgressListing = props => {
  let edges = props.edges

  // TOOD Might want to do this with CSS Grid,
  // with the heading part of the grid
  return (
    <ul className="list-style-none m0 mb2 p0 pt2 md-flex px2 md-px1 lg-px2 flex-wrap">
      {edges && edges.length >= 1
        ? edges.map(({ node }, index) => {
            // Remove placeholder project
            // TODO Make this generic
            if (node.slug.includes('placeholder')) {
              return false
            }

            let projectDetails = node.projectDetails
            let meta = [
              { name: 'Client', content: projectDetails.client },
              {
                name: 'Location & Completion',
                content: projectDetails.year
                  ? `${projectDetails.location} (${projectDetails.year})`
                  : projectDetails.location,
              },
            ]

            let scopeOfWorkList = formatScopeOfWork(
              projectDetails.projectRelatedexpertise,
              projectDetails.projectRelatedexpertiseOther
            )

            if (scopeOfWorkList) {
              meta.splice(1, 0, {
                name: 'Scope of Work',
                content: scopeOfWorkList.join(', '),
              })
            }

            return (
              <li
                key={`InProgressItem_${index}`}
                className="flex col-12 md-col-6 mb-line md-mb2 lg-mb3 md-px1 lg-px2">
                <div className="col-12 block bg-white black">
                  <Carousel gallery={node.projectMainContent.gallery} />
                  <div className="p2 pt3 sm-p3">
                    <H2 color="magenta" xs="h4">
                      {unesc(node.title)}
                    </H2>
                    <p className="mt0 mb3 h4 md-h4 line-height-3">
                      {node.details && node.details.description
                        ? node.details.description
                        : null}
                    </p>
                    <InProgressMeta meta={meta} />
                  </div>
                </div>
              </li>
            )
          })
        : null}
    </ul>
  )
}

InProgressListing.defaultProps = {
  edges: [],
  slugPrefix: '/our-work',
}

InProgressListing.propTypes = {
  edges: PropTypes.array.isRequired,
  slugPrefix: PropTypes.string,
}

export default InProgressListing
