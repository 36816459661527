import React from 'react'
import { graphql } from 'gatsby'

// Ours
import stripTrailingSlash from '../utils/strip-trailing-slash'
import SEO from '../components/SEO'
import { BlockWrapper } from '../components/MainBlocks'
import InProgressListing from '../components/InProgressListing'
import CallToAction from '../components/CallToAction'

const InProgressPage = props => {
  const data = props.data
  let title = 'In-progress'
  let slugPrefix = stripTrailingSlash(props.location.pathname)

  return (
    <div className="pt0 lg-pt2">
      <SEO title={title} />
      <h1 className="hide">{title}</h1>
      {data.inProgress ? (
        <BlockWrapper maxWidth={6} px={false} py={false} bg="transparent">
          <InProgressListing
            edges={data.inProgress.edges}
            slugPrefix={slugPrefix}
          />
        </BlockWrapper>
      ) : (
        <div className="min-height-25vh" />
      )}
      <CallToAction />
    </div>
  )
}

export default InProgressPage

export const query = graphql`
  query InProgressQuery {
    inProgress: allWpProject(
      filter: { projectDetails: { projectStatus: { eq: "in-progress" } } }
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          title
          slug
          uri
          date
          details {
            description
          }
          projectMainContent {
            gallery {
              localFile {
                childImageSharp {
                  id
                  fluid(maxWidth: 1280) {
                    src
                    srcSet
                  }
                }
              }
            }
          }
          projectDetails {
            projectRelatedexpertise {
              ... on WpExpertise {
                title
                # databaseId
              }
            }
            projectRelatedexpertiseOther {
              ... on WpProject_Projectdetails_projectRelatedexpertiseOther {
                postTitle
              }
            }
            projectStatus
            client
            location
            year
          }
        }
      }
    }
  }
`
